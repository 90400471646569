import React, { useState, useEffect } from 'react';
import '../../styles/AddressForm.scss';

const INDIAN_STATES = [
  'Andhra Pradesh', 'Delhi', 'Gujarat', 'Karnataka', 'Kerala',
  'Maharashtra', 'Tamil Nadu', 'Telangana', 'Uttar Pradesh', 'West Bengal'
];

const ADDRESS_TYPES = [
  { value: 'Home', label: 'Home' },
  { value: 'Office', label: 'Office' },
  { value: 'Other', label: 'Other' }
];

const INITIAL_FORM_STATE = {
  type: 'Home',
  fullName: '',
  phone: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  pincode: '',
  isDefault: false
};

const VALIDATION_RULES = {
  fullName: (value) => !value.trim() && 'Name is required',
  phone: (value) => {
    if (!value) return 'Phone is required';
    if (!/^[1-9]\d{9}$/.test(value)) return 'Enter valid 10-digit phone number';
  },
  email: (value) => value && !/^\S+@\S+\.\S+$/.test(value) && 'Enter valid email address',
  address1: (value) => !value.trim() && 'Address is required',
  city: (value) => !value.trim() && 'City is required',
  state: (value) => !value && 'State is required',
  pincode: (value) => {
    if (!value) return 'Pincode is required';
    if (!/^\d{6}$/.test(value)) return 'Enter valid 6-digit pincode';
  }
};

const FormField = ({ label, error, children, required }) => (
  <div className="form-group">
    <label>
      {label} {required && '*'}
    </label>
    {children}
    {error && <span className="error-message">{error}</span>}
  </div>
);

const AddressForm = ({ onSubmit, onClose, existingAddresses = [] }) => {
  const [formData, setFormData] = useState({
    ...INITIAL_FORM_STATE,
    isDefault: existingAddresses.length === 0
  });
  const [errors, setErrors] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [showSavedAddresses, setShowSavedAddresses] = useState(true);

  useEffect(() => {
    const savedAddresses = JSON.parse(localStorage.getItem('addresses') || '[]');
    setAddresses(savedAddresses);
  }, []);

  const validateForm = () => {
    const newErrors = {};
    Object.entries(VALIDATION_RULES).forEach(([field, validate]) => {
      const error = validate(formData[field]);
      if (error) newErrors[field] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = ({ target: { name, value, type, checked } }) => {
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const newAddress = { ...formData, id: Date.now() };
      const updatedAddresses = [...addresses];
      
      if (formData.isDefault) {
        updatedAddresses.forEach(addr => addr.isDefault = false);
      }
      
      updatedAddresses.push(newAddress);
      localStorage.setItem('addresses', JSON.stringify(updatedAddresses));
      onSubmit(newAddress);
    }
  };

  const selectAddress = (address) => {
    onSubmit(address);
    onClose();
  };

  const inputProps = (name, type = 'text', extras = {}) => ({
    id: name,
    name,
    value: formData[name],
    onChange: handleChange,
    className: errors[name] ? 'error' : '',
    type,
    ...extras
  });

  if (showSavedAddresses && addresses.length > 0) {
    return (
      <div className="address-form-overlay" onClick={e => e.target === e.currentTarget && onClose()}>
        <div className="address-form">
          <div className="form-header">
            <h2>Select Address</h2>
            <button type="button" className="close-button" onClick={onClose} aria-label="Close">
              &times;
            </button>
          </div>
          
          <div className="saved-addresses">
            {addresses.map(address => (
              <div key={address.id} className="saved-address" onClick={() => selectAddress(address)}>
                <div className="address-type">{address.type}</div>
                <div className="address-details">
                  <p className="name">{address.fullName}</p>
                  <p>{address.address1}</p>
                  {address.address2 && <p>{address.address2}</p>}
                  <p>{address.city}, {address.state} - {address.pincode}</p>
                  <p>{address.phone}</p>
                </div>
                {address.isDefault && <div className="default-badge">Default</div>}
              </div>
            ))}
          </div>
          
          <div className="form-actions">
            <button type="button" className="submit-btn" onClick={() => setShowSavedAddresses(false)}>
              Add New Address
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="address-form-overlay" onClick={e => e.target === e.currentTarget && onClose()}>
      <div className="address-form">
        <div className="form-header">
          <h2>Add New Address</h2>
          <button type="button" className="close-button" onClick={onClose} aria-label="Close">
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} noValidate>
          <div className="form-grid">
            {/* Original form fields remain unchanged */}
            <FormField label="Address Type">
              <select {...inputProps('type')}>
                {ADDRESS_TYPES.map(({ value, label }) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </FormField>

            <FormField label="Full Name" error={errors.fullName} required>
              <input {...inputProps('fullName')} />
            </FormField>

            <FormField label="Phone Number" error={errors.phone} required>
              <input {...inputProps('phone', 'tel', { maxLength: 10 })} />
            </FormField>

            <FormField label="Email" error={errors.email}>
              <input {...inputProps('email', 'email')} />
            </FormField>

            <FormField label="Address Line 1" error={errors.address1} required className="form-full">
              <input {...inputProps('address1', 'text', { 
                placeholder: 'House No, Building Name'
              })} />
            </FormField>

            <FormField label="Address Line 2" className="form-full">
              <input {...inputProps('address2', 'text', {
                placeholder: 'Road Name, Area (Optional)'
              })} />
            </FormField>

            <FormField label="City" error={errors.city} required>
              <input {...inputProps('city')} />
            </FormField>

            <FormField label="State" error={errors.state} required>
              <select {...inputProps('state')}>
                <option value="">Select State</option>
                {INDIAN_STATES.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </FormField>

            <FormField label="Pincode" error={errors.pincode} required>
              <input {...inputProps('pincode', 'text', { maxLength: 6 })} />
            </FormField>

            <div className="form-group form-full checkbox-group">
              <label className="checkbox-label">
                <input {...inputProps('isDefault', 'checkbox')} />
                <span>Make this my default address</span>
              </label>
            </div>

            <div className="form-actions">
              {addresses.length > 0 && (
                <button type="button" className="cancel-btn" onClick={() => setShowSavedAddresses(true)}>
                  Use Saved Address
                </button>
              )}
              <button type="button" className="cancel-btn" onClick={onClose}>
                Cancel
              </button>
              <button type="submit" className="submit-btn">
                Save Address
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressForm;