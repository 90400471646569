import React from 'react';
import { AlertTriangle } from 'lucide-react';
import '../../styles/CautionNotice.css'

const CautionNotice = () => {
  return (
    <div className="caution-notice">
      <AlertTriangle size={20} className="caution-notice__icon" />
      <p className="caution-notice__text">
        Caution: Please be aware that our products should be consumed only as directed. If you experience any adverse effects, discontinue use and consult a healthcare professional immediately. Keep out of reach of children. Store in a cool, dry place away from direct sunlight.
      </p>
    </div>
  );
};

export default CautionNotice;