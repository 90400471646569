import React, { useState } from 'react';
import '../../styles/terms.scss'

const TermsSection = ({ title, children, isOpen, onToggle }) => (
  <div className="terms-section">
    <button
      className="terms-section__button"
      onClick={onToggle}
      aria-expanded={isOpen}
    >
      <h2>{title}</h2>
      <span className={`toggle-icon ${isOpen ? 'toggle-icon--open' : ''}`}>
        ⌄
      </span>
    </button>
    <div 
      className={`terms-section__content ${isOpen ? 'terms-section__content--open' : ''}`}
      aria-hidden={!isOpen}
    >
      {children}
    </div>
  </div>
);

const TermsAndConditions = () => {
  const [openSections, setOpenSections] = useState({
    definitions: true,
    general: false,
    shipping: false,
    contact: false
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className="terms-container">
      <div className="terms-content">
        <div className="terms-header">
          <h1>Terms and Conditions</h1>
        </div>

        <div className="terms-sections">
          <TermsSection 
            title="1. Definitions" 
            isOpen={openSections.definitions}
            onToggle={() => toggleSection('definitions')}
          >
            <ul>
              <li>"We", "us", "our" refers to Periodic Industries, located at PLOT NO 65, SURVEY NO 213-214, LAKHABAVAD, Jamnagar, Gujarat, 361006</li>
              <li>"You", "your", "user", "visitor" refers to any natural or legal person visiting our website and/or agreeing to purchase from us</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="2. General Terms" 
            isOpen={openSections.general}
            onToggle={() => toggleSection('general')}
          >
            <p>1. The content of this website is subject to change without notice.</p>
            
            <p>2. <strong>Disclaimer of Warranties</strong></p>
            <ul>
              <li>Neither we nor any third parties provide any warranty regarding the accuracy, timeliness, performance, completeness, or suitability of the information and materials found on this website</li>
              <li>You acknowledge that information may contain inaccuracies or errors</li>
              <li>Use of any information or materials is entirely at your own risk</li>
            </ul>

            <p>3. <strong>Intellectual Property</strong></p>
            <ul>
              <li>All website material is owned by or licensed to us</li>
              <li>This includes design, layout, look, appearance, and graphics</li>
              <li>Reproduction is prohibited except in accordance with the copyright notice</li>
              <li>All trademarks are acknowledged</li>
            </ul>

            <p>4. <strong>Website Usage</strong></p>
            <ul>
              <li>Unauthorized use of information shall result in damages claim and/or criminal offense</li>
              <li>You may not create links to our website without prior written consent</li>
              <li>We may provide links to other websites for convenience</li>
            </ul>

            <p>5. <strong>Legal Jurisdiction</strong></p>
            <ul>
              <li>Any disputes are subject to the laws of India</li>
            </ul>

            <p>6. <strong>Liability</strong></p>
            <ul>
              <li>We shall not be liable for any declined transactions due to preset limits with our acquiring bank</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="3. Shipping and Delivery Policy" 
            isOpen={openSections.shipping}
            onToggle={() => toggleSection('shipping')}
          >
            <p>1. <strong>Delivery Methods</strong></p>
            <ul>
              <li>International: Shipped through registered international courier/speed post</li>
              <li>Domestic: Shipped through registered domestic courier/speed post</li>
            </ul>

            <p>2. <strong>Shipping Timeline</strong></p>
            <ul>
              <li>Orders shipped within 0-7 days or as per agreed delivery date</li>
              <li>Not liable for courier company/postal delays</li>
              <li>Delivery to address provided by buyer</li>
            </ul>

            <p>3. <strong>Support</strong></p>
            <ul>
              <li>Contact helpdesk: 7788994668</li>
              <li>Email: CARE@BUYMALAK.IN</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="4. Contact Information" 
            isOpen={openSections.contact}
            onToggle={() => toggleSection('contact')}
          >
            <ul>
              <li><strong>Company Name:</strong> Periodic Industries</li>
              <li><strong>Address:</strong> PLOT NO 65, SURVEY NO 213-214, LAKHABAVAD, Jamnagar, Gujarat, 361006</li>
              <li><strong>Phone:</strong> 7788994668</li>
              <li><strong>Email:</strong> CARE@BUYMALAK.IN</li>
            </ul>
          </TermsSection>
        </div>

        <div className="terms-footer">
          <p>If you have any questions about these terms, please contact us at CARE@BUYMALAK.IN</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;