import React, { useState, useEffect } from 'react';
import ImageSlider from './HomePageComponents/sliderImage';
import CustomerAttractionStrip from './HomePageComponents/smallStrip'; 
import BestSellers from './HomePageComponents/BestSeller'; 
import ComboBanner from './HomePageComponents/ComboOfferBanner';
import BestSellerStrip from './HomePageComponents/BestSellerStrip';
import ComboOffers from './HomePageComponents/ComboOffer'; 
import ProductShowcase from './HomePageComponents/ProductShowcase'; 
import Reviews from './HomePageComponents/Reviews'; 
import Footer from './HomePageComponents/Footer'; 
import SingleBanner from './HomePageComponents/SingleBanner'; 
import CautionNotice from './HomePageComponents/CautionNotice'
import '../styles/BestSeller.scss'; 

function HomePage() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  const images = [
    '/website banner-02.jpg',
    '/website banner-04.jpg',
    '/website banner-05.jpg',
  ];

  return (
    <>
      {showContent && (
        <>
         <SingleBanner 
  imageUrl="/Comp1.gif" 
  width="98%"
  height="100%"
/>
          <CustomerAttractionStrip />
          <BestSellerStrip />
          <BestSellers />
<ImageSlider images={images} interval={5000} />
          <ComboBanner />
          <ComboOffers />
          <ProductShowcase />
          <Reviews />
          <CautionNotice />
          <Footer />
        </>
      )}
    </>
  );
}

export default HomePage;
