// SingleBanner.jsx
import React from 'react';
import '../../styles/SingleBanner.scss';

const SingleBanner = ({ 
  imageUrl, 
  altText = 'Banner image',
  height = '100px',
  width = '100%' // New width prop with default value
}) => {
  return (
    <div className="single-banner-container">
      <div 
        className="banner-wrapper"
        style={{ maxWidth: width }} // Apply width to wrapper
      >
        <div 
          className="banner-content"
          style={{ height }}
        >
          <img
            src={imageUrl}
            alt={altText}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleBanner;