import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Grid,
  Alert,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../../helpers/axiosconfig';

const LoginModal = ({ isOpen, onClose, onLoginSuccess }) => {
  const [step, setStep] = useState('phone');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);
  const [notifyUpdates, setNotifyUpdates] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMobileNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      setMobileNumber(value);
      setError('');
    }
  };

  const handleBackToPhone = () => {
    setStep('phone');
    setOtp(['', '', '', '']);
    setError('');
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && !isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setError('');
      
      // Only move to next input if there's a value and we're not at the last input
      if (value && index < 3) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
      
      // Check if this was the last digit and all digits are filled
      if (index === 3 && newOtp.every(digit => digit !== '')) {
        // Blur the current input to close the keyboard
        document.activeElement.blur();
      }
    }
  };

  const handleKeyPress = (event, currentStep) => {
    if (event.key === 'Enter') {
      if (currentStep === 'phone') {
        handleSendOtp();
      } else if (currentStep === 'otp') {
        handleVerifyOtp();
      }
    }
  };

  const handleSendOtp = async () => {
    if (mobileNumber.length !== 10) {
      setError('Please enter a valid 10-digit mobile number');
      return;
    }

    try {
      setIsLoading(true);
      setError('');

      const response = await api.post('auth/send-otp', {
        mobileNumber: mobileNumber
      });

      if (response.data.success) {
        setIsNewUser(response.data.isNewUser);
        setStep('otp');
      } else {
        throw new Error(response.data.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Send OTP Error:', error);
      setError(error.response?.data?.message || 'Failed to send OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    const otpString = otp.join('');
    if (otpString.length !== 4) {
      setError('Please enter a valid 4-digit OTP');
      return;
    }
  
    try {
      setIsLoading(true);
      setError('');
  
      const response = await api.post('auth/verify-otp', {
        mobileNumber: mobileNumber,
        otp: otpString,
      });
  
      if (response.data.success) {
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        }
        
        if (response.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        
        setStep('success');
        
        setTimeout(() => {
          onClose();
          setStep('phone');
          setMobileNumber('');
          setOtp(['', '', '', '']);
          if (onLoginSuccess) {
            onLoginSuccess(response.data);
          }
          
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(response.data.message || 'Invalid OTP');
      }
    } catch (error) {
      console.error('Verify OTP Error:', error);
      setError(error.response?.data?.message || 'Failed to verify OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const features = [
    {
      title: "Experience Free Forever",
      desc: "Experience without any subscription fees"
    },
    {
      title: "Best offers",
      desc: "Enjoy unbeatable offers after login"
    },
    {
      title: "100% Secure & Spam Free",
      desc: "Your data is safeguarded and spam-free"
    }
  ];

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden',
          m: 2,
          maxHeight: isMobile ? '90vh' : 'none'
        }
      }}
    >
      <Grid container sx={{ maxHeight: isMobile ? '90vh' : 'none', overflow: 'auto' }}>
        {/* Left Section */}
        <Grid 
          item 
          xs={12} 
          md={6} 
          sx={{ 
            bgcolor: '#066469', 
            position: 'relative', 
            p: isMobile ? 2 : 4,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ 
            textAlign: 'center', 
            mb: isMobile ? 3 : 8,
            mt: isMobile ? 2 : 0
          }}>
            <Box 
              component="img"
              src="/malak-removebg-preview.jpg"
              alt="Logo"
              sx={{ 
                width: isMobile ? 120 : 150,
                height: isMobile ? 51 : 64,
                mb: isMobile ? 2 : 3
              }}
            />
            <Typography 
              variant={isMobile ? "h6" : "h5"} 
              sx={{ color: 'white', fontWeight: 'bold' }}
            >
              Login now to access best offers!
            </Typography>
          </Box>

          <Grid container spacing={isMobile ? 1 : 2}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper 
                  elevation={0}
                  sx={{
                    p: isMobile ? 1.5 : 2,
                    bgcolor: 'transparent',
                    border: '1px solid rgba(255,255,255,0.2)',
                    borderRadius: 2,
                    textAlign: 'center',
                    height: '100%'
                  }}
                >
                  <StarIcon sx={{ 
                    color: '#FFD700', 
                    fontSize: isMobile ? 24 : 32, 
                    mb: 1 
                  }} />
                  <Typography 
                    variant={isMobile ? "body1" : "subtitle1"} 
                    sx={{ color: 'white', fontWeight: 'bold', mb: 0.5 }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(255,255,255,0.8)',
                      fontSize: isMobile ? '0.75rem' : '0.875rem'
                    }}
                  >
                    {feature.desc}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: isMobile ? 2 : 4 }}>
            {step === 'otp' && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton 
                  onClick={handleBackToPhone}
                  sx={{ mr: 1 }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="body1">
                  Change Number
                </Typography>
              </Box>
            )}

            <Typography variant={isMobile ? "h6" : "h5"} sx={{ fontWeight: 'bold', mb: isMobile ? 2 : 4 }}>
              {isNewUser ? 'Create Account' : 'Login / Signup'}
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            {step === 'phone' && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 2 : 3 }}>
                <TextField
                  fullWidth
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                  onKeyPress={(e) => handleKeyPress(e, 'phone')}
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                  error={!!error}
                />

                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={notifyUpdates}
                      onChange={(e) => setNotifyUpdates(e.target.checked)}
                    />
                  }
                  label="Notify me for any updates & offers"
                />

                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleSendOtp}
                  disabled={mobileNumber.length !== 10 || isLoading}
                  sx={{ 
                    bgcolor: '#066469',
                    '&:hover': { bgcolor: '#055459' }
                  }}
                >
                  {isLoading ? 'Sending OTP...' : 'Send OTP'}
                </Button>
              </Box>
            )}

{step === 'otp' && (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 2 : 3 }}>
      <Alert severity="info" sx={{ mb: isMobile ? 1 : 2 }}>
        OTP sent to +91 {mobileNumber}
      </Alert>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
        {otp.map((digit, index) => (
          <TextField
            key={index}
            id={`otp-${index}`}
            value={digit}
            onChange={(e) => handleOtpChange(index, e.target.value)}
            onKeyPress={(e) => handleKeyPress(e, 'otp')}
            variant="outlined"
            error={!!error}
            inputProps={{ 
              maxLength: 1,
              style: { textAlign: 'center' },
              pattern: '[0-9]*',  // Shows numeric keypad on iOS
              inputMode: 'numeric', // Shows numeric keypad on Android
              type: 'tel' // Additional support for numeric keypad
            }}
            sx={{ width: isMobile ? '3rem' : '4rem' }}
          />
        ))}
      </Box>

                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleVerifyOtp}
                  disabled={otp.join('').length !== 4 || isLoading}
                  sx={{ 
                    bgcolor: '#066469',
                    '&:hover': { bgcolor: '#055459' }
                  }}
                >
                  {isLoading ? 'Verifying...' : 'Verify OTP'}
                </Button>

                <Button
                  variant="text"
                  onClick={handleSendOtp}
                  disabled={isLoading}
                  sx={{ mt: 1 }}
                >
                  Resend OTP
                </Button>
              </Box>
            )}

            {step === 'success' && (
              <Box sx={{ textAlign: 'center', py: isMobile ? 2 : 4 }}>
                <CheckCircleIcon 
                  sx={{ 
                    fontSize: isMobile ? 36 : 48, 
                    color: '#4CAF50',
                    mb: 2
                  }} 
                />
                <Typography variant="h6" sx={{ color: '#4CAF50', fontWeight: 'bold', mb: 1 }}>
                  {isNewUser ? 'Account Created Successfully!' : 'Login Successful!'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Redirecting you to the dashboard...
                </Typography>
              </Box>
            )}

            <Box sx={{ 
              mt: 2, 
              pt: isMobile ? 4 : 10, 
              textAlign: 'center'
            }}>
              <Typography variant="caption" display="block" sx={{ mb: 1 }}>
                I accept that I have read & understood{' '}
                <Box component="a" href="#" sx={{ color: 'primary.main' }}>
                  Privacy Policy
                </Box>
                {' '}and{' '}
                <Box component="a" href="#" sx={{ color: 'primary.main' }}>
                  T&Cs
                </Box>
              </Typography>
              <Typography variant="body2">
                <Box 
                  component="a" 
                  href="#" 
                  sx={{ 
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  Trouble logging in?
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LoginModal;