import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../../helpers/axiosconfig';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingLocation, setPendingLocation] = useState(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const storedUser = localStorage.getItem('user');
      const token = localStorage.getItem('token');

      if (token && storedUser) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setUser(JSON.parse(storedUser));
      }
    } catch (error) {
      console.error('Auth status check failed:', error);
      handleLogout();
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = (userData) => {
    if (!userData || !userData.user || !userData.token) {
      console.error('Invalid login data received');
      return;
    }

    // Immediately set the user in state
    setUser(userData.user);
    
    // Set up API authorization
    api.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
    
    // Store in localStorage after state is updated
    localStorage.setItem('token', userData.token);
    localStorage.setItem('user', JSON.stringify(userData.user));
    
    // Handle pending location if exists
    if (pendingLocation) {
      window.location.href = pendingLocation;
      setPendingLocation(null);
    }
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    delete api.defaults.headers.common['Authorization'];
  };

  const openAuthModal = (targetLocation) => {
    setIsModalOpen(true);
    setPendingLocation(targetLocation);
  };

  const closeAuthModal = () => {
    setIsModalOpen(false);
    setPendingLocation(null);
  };

  // Value object created outside of return for better performance
  const authValue = {
    user,
    isAuthenticated: !!user,
    isLoading,
    login: handleLogin,
    logout: handleLogout,
    openAuthModal,
    closeAuthModal,
    isModalOpen,
    pendingLocation
  };

  return (
    <AuthContext.Provider value={authValue}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};