import React, { useEffect, useRef } from 'react';
import '../../styles/Reviews.scss';

const reviews = [
  {
    name: "Hema Sharma",
    role: "Mother",
    content: "MALAK Liquid Detergent is a blessing for me. My kids are naughty and soil their clothes often. With completely natural MALAK Liquid Detergent, now it's easy to remove stains without having to fear how the detergent will affect the colour, fabric & texture of the garments."
  },
  {
    name: "Mona Kapoor",
    role: "Mother",
    content: "Having a kid at home means I need to ensure that all surfaces are clean and germ-free, that's why I trust MALAK All Purpose Cleaner - completely natural, and can be effectively used to clean all type of surfaces. It also leaves a rich lavender fragrance that makes my home smell 'clean' and beautiful."
  },
  {
    name: "Vandita Chaurasia",
    role: "Mother",
    content: "As a mother, I make sure that every corner of my house is not only clean, but is also germ-free, and that's why I use MALAK Floor Cleaner. It is completely natural, make floors squeaky clean, kill germs, dries in a blink (perfect for my restless baby) and leaves a pleasant fragrance."
  },
  {
    name: "S Bansal",
    role: "Father (34 Yrs Old)",
    content: "Their Liquid detergent is a dream. Its a no brainer in terms of choice ingredient but the best part is that some old stain of curry disappeared."
  }
];

const Reviews = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const scrollWidth = scrollContainer.scrollWidth;
      const animationDuration = scrollWidth / 50; // Adjust speed here

      scrollContainer.style.setProperty('--scroll-width', `${scrollWidth}px`);
      scrollContainer.style.setProperty('--animation-duration', `${animationDuration}s`);
    }
  }, []);

  return (
    <div className="reviews-container">
      <div className="reviews-scroll" ref={scrollRef}>
        <div className="reviews-track">
          {[...reviews, ...reviews].map((review, index) => (
            <div key={index} className="review-card">
              <div className="review-content">
                <p>{review.content}</p>
              </div>
              <div className="review-footer">
                <div className="review-icon">
                  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                    <path d="M20 2H4c-1.103 0-2 .897-2 2v18l5.333-4H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14H6.667L4 18V4h16v12z" />
                  </svg>
                </div>
                <div className="review-info">
                  <span className="review-name">{review.name}</span>
                  <span className="review-role">{review.role}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;