// CartContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  // Initialize cart from localStorage
  useEffect(() => {
    try {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      setCart(storedCart);
    } catch (error) {
      console.error('Error loading cart from storage:', error);
      setCart([]);
      localStorage.setItem('cart', JSON.stringify([]));
    }
  }, []);

  // Update localStorage whenever cart changes
  useEffect(() => {
    try {
      localStorage.setItem('cart', JSON.stringify(cart));
      // Dispatch event for other components
      window.dispatchEvent(new CustomEvent('cartUpdate', {
        detail: { cart }
      }));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  }, [cart]);

  const addToCart = (item) => {
    setCart(prevCart => {
      // Check if item already exists in cart
      const existingItemIndex = prevCart.findIndex(
        cartItem => 
          cartItem.productId === item.productId && 
          cartItem.variationId === item.variationId
      );

      if (existingItemIndex > -1) {
        // Update existing item quantity
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: updatedCart[existingItemIndex].quantity + (item.quantity || 1)
        };
        return updatedCart;
      }

      // Add new item
      return [...prevCart, { ...item, quantity: item.quantity || 1 }];
    });

    toast.success(`${item.name} added to cart`);
  };

  const removeFromCart = (productId, variationId) => {
    setCart(prevCart => {
      const updatedCart = prevCart.filter(item => 
        !(item.productId === productId && item.variationId === variationId)
      );
      return updatedCart;
    });
  };

  const updateQuantity = (productId, variationId, change) => {
    setCart(prevCart => {
      const updatedCart = prevCart.map(item => {
        if (item.productId === productId && item.variationId === variationId) {
          const newQuantity = Math.max(1, item.quantity + change);
          return { ...item, quantity: newQuantity };
        }
        return item;
      }).filter(item => item.quantity > 0);
      
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{
      cart,
      addToCart,
      removeFromCart,
      updateQuantity,
      clearCart
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};