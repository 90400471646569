import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
  Button,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ShoppingBag,
  Category,
  Description,
  AssignmentReturn,
  ChevronRight,
  ChevronLeft,
  Close,
  ShoppingBasket,
} from '@mui/icons-material';
import { useAuth } from '../Signin_up/AuthContext';

const Sidebar = ({ isOpen, onClose, onOpenSignIn }) => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubItem, setActiveSubItem] = useState(null);
  const { isAuthenticated } = useAuth();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const tealBlue = '#008080';

  const menuItems = [
    { 
      name: 'All Products', 
      icon: <ShoppingBag />,
      color: tealBlue,
      subItems: [
        { name: 'All Products' },
        { name: 'Laundry Care' },
        { name: 'Kitchen Care' },
        { name: 'Surface Care' },
        { name: 'Bathroom Care' }
      ]
    },
    { 
      name: 'Categories', 
      icon: <Category />,
      color: tealBlue,
      subItems: [
        { name: 'Home Care' },
        { name: 'Personal Care' }
      ]
    },
    {
      name: 'My Orders',
      icon: <ShoppingBasket />,
      link: '/orders',
      color: tealBlue
    },
    { 
      name: 'Terms of Service', 
      icon: <Description />,
      link: '#',
      color: tealBlue
    },
    { 
      name: 'Refund and Return Policy', 
      icon: <AssignmentReturn />,
      link: '#',
      color: tealBlue
    },
  ];

  const handleCategoryClick = (index) => {
    const item = menuItems[index];
    
    if (item.name === 'Terms of Service') {
      navigate('/terms');
      onClose();
      return;
    }
    if (item.name === 'Refund and Return Policy') {
      navigate('/privacy');
      onClose();
      return;
    }
    if (item.name === 'My Orders') {
      if (isAuthenticated) {
        navigate('/orders');
        onClose();
      } else {
        onClose();
        onOpenSignIn();
      }
      return;
    }
    
    setActiveCategory(menuItems[index]);
    setActiveSubItem(null);
  };

  const handleSubItemClick = (category, subItem, index) => {
    setActiveSubItem(index);
    
    let queryParam = '';
    if (category.name === 'All Products') {
      // For All Products category, use the subItem name directly as the filter
      queryParam = subItem.name === 'All Products' 
        ? '' 
        : `?category=${encodeURIComponent(subItem.name)}`;
    } else if (category.name === 'Categories') {
      queryParam = `?category=${encodeURIComponent(subItem.name)}`;
    }
    
    navigate(`/products${queryParam}`);
    onClose();
  };

  const drawerWidth = isMobile ? '100%' : (activeCategory ? 600 : 300);

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          overflow: 'hidden',
          bgcolor: '#FAFAFA'
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        height: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}>
        {/* Main Menu */}
        <Box sx={{ 
          width: isMobile ? '100%' : 300, 
          borderRight: '1px solid rgba(0, 0, 0, 0.08)',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fff'
        }}>
          <Box sx={{ 
            p: 3, 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
          }}>
            <IconButton 
              onClick={onClose} 
              size="small"
              sx={{ 
                bgcolor: 'rgba(0, 0, 0, 0.04)',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.08)' }
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>

          <List sx={{ flexGrow: 1, overflow: 'auto', p: 1.5 }}>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem 
                  disablePadding
                  sx={{ mb: 0.5 }}
                >
                  <ListItemButton
                    onClick={() => handleCategoryClick(index)}
                    selected={activeCategory === menuItems[index]}
                    sx={{
                      borderRadius: 2,
                      '&.Mui-selected': {
                        bgcolor: `${item.color}15`,
                        '&:hover': {
                          bgcolor: `${item.color}25`,
                        }
                      },
                      '&:hover': {
                        bgcolor: `${item.color}10`,
                      }
                    }}
                  >
                    <Box sx={{ mr: 2, color: item.color }}>{item.icon}</Box>
                    <ListItemText 
                      primary={item.name}
                      primaryTypographyProps={{
                        fontSize: '0.95rem',
                        fontWeight: activeCategory === menuItems[index] ? 600 : 500,
                        color: tealBlue
                      }}
                    />
                    {item.subItems && (
                      <ChevronRight sx={{ ml: 1, color: item.color, opacity: 0.8 }} />
                    )}
                  </ListItemButton>
                </ListItem>
                {index < menuItems.length - 1 && (
                  <Divider sx={{ my: 1.5 }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>

        {/* Sub Items */}
        <Box sx={{ 
          position: isMobile ? 'fixed' : 'absolute',
          top: 0,
          left: isMobile ? 0 : 300,
          width: isMobile ? '100%' : 300,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fff',
          transform: activeCategory ? 'translateX(0)' : isMobile ? 'translateX(100%)' : 'translateX(-100%)',
          opacity: activeCategory ? 1 : 0,
          visibility: activeCategory ? 'visible' : 'hidden',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          boxShadow: '-4px 0 8px rgba(0,0,0,0.05)',
          zIndex: isMobile ? theme.zIndex.drawer + 1 : 'auto'
        }}>
          <Box sx={{ 
            p: 3, 
            display: 'flex', 
            alignItems: 'center',
            gap: 1.5,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
          }}>
            <IconButton 
              onClick={() => setActiveCategory(null)}
              size="small"
              sx={{ 
                bgcolor: `${activeCategory?.color}15`,
                color: activeCategory?.color,
                '&:hover': { bgcolor: `${activeCategory?.color}25` }
              }}
            >
              <ChevronLeft />
            </IconButton>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: tealBlue,
                fontWeight: 600
              }}
            >
              {activeCategory?.name}
            </Typography>
          </Box>

          <List sx={{ flexGrow: 1, overflow: 'auto', p: 1.5 }}>
            {activeCategory?.subItems?.map((subItem, index) => (
              <React.Fragment key={index}>
                <ListItem 
                  disablePadding
                  sx={{ mb: 0.5 }}
                >
                  <ListItemButton
                    onClick={() => handleSubItemClick(activeCategory, subItem, index)}
                    selected={activeSubItem === index}
                    sx={{
                      borderRadius: 2,
                      '&.Mui-selected': {
                        bgcolor: `${tealBlue}15`,
                        '&:hover': {
                          bgcolor: `${tealBlue}25`,
                        }
                      },
                      '&:hover': {
                        bgcolor: `${tealBlue}10`,
                      }
                    }}
                  >
                    <ListItemText
                      primary={subItem.name}
                      primaryTypographyProps={{
                        fontSize: '0.95rem',
                        color: tealBlue,
                        fontWeight: activeSubItem === index ? 600 : 500
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                {index < (activeCategory?.subItems?.length - 1) && (
                  <Divider sx={{ my: 1.5 }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;