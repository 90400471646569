// CustomerAttractionStrip.jsx
import React, { useState, useEffect } from 'react';
import '../../styles/CustomerAttractionStrip.scss';

const phrases = [
  { text: "Power Plant", icon: "⚡" },
  { text: "Cosmos Certified", icon: "🛡️" },
  { text: "Eco-Friendly", icon: "🍃" },
  { text: "Innovative Energy", icon: "💡" },
  { text: "Reliable Provider", icon: "🔋" },
  { text: "Sustainable Future", icon: "♻️" },
  { text: "Greener Tomorrow", icon: "🌞" },
  { text: "Clean Energy", icon: "🌬️" }
];

const CustomerAttractionStrip = () => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) => (prevPosition + 1) % (phrases.length * 100));
    }, 100000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="customer-attraction-strip">
      <div className="strip-content">
        <span className="chevron left">‹</span>
        <div className="phrases-container">
          {phrases.concat(phrases).map((phrase, index) => (
            <span
              key={index}
              className="phrase"
              style={{
                transform: `translateX(${-position}%)`,
              }}
            >
              <span className="icon">{phrase.icon}</span>
              {phrase.text}
            </span>
          ))}
        </div>
        <span className="chevron right">›</span>
      </div>
    </div>
  );
};

export default CustomerAttractionStrip;