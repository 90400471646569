import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../helpers/axiosconfig';
import { toast } from 'react-toastify';
import AddressForm from '../order/addressForm';
import LoginModal from '../Signin_up/Signin';
import { useAuth } from '../Signin_up/AuthContext';
import { useCart } from '../../utils/CartContext';
import { useCartUI } from '../../utils/CartUIContext';



const CartSidebar = () => {
  const { isCartOpen, closeCart } = useCartUI();
  const { cart, removeFromCart, updateQuantity, clearCart } = useCart();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [coupons] = useState([
    { id: 1, code: 'SAVE10', discount: 10, type: 'percentage' },
    { id: 2, code: 'FLAT50', discount: 50, type: 'fixed' },
    { id: 3, code: 'SUMMER20', discount: 20, type: 'percentage' },
  ]);

   // Single useEffect for cart synchronization
   useEffect(() => {
    const loadCartFromStorage = () => {
      try {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        // setCart(storedCart);
      } catch (error) {
        console.error('Error loading cart from storage:', error);
        // setCart([]);
        // localStorage.setItem('cart', JSON.stringify([]));
      }
    };

    loadCartFromStorage();
  }, []); 

  const updateLocalStorage = (updatedCart) => {
    try {
      localStorage.setItem('cart', JSON.stringify(updatedCart));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  };

  useEffect(() => {
    const loadRazorpay = async () => {
      if (document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
        setRazorpayLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = () => setRazorpayLoaded(true);
      document.body.appendChild(script);
    };

    loadRazorpay();
  }, []);

  const syncCartWithLocalStorage = () => {
    try {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      // Remove any duplicate items based on productId and variationId combination
      const uniqueCart = storedCart.reduce((acc, current) => {
        const key = `${current.productId}-${current.variationId || ''}`;
        if (!acc.some(item => `${item.productId}-${item.variationId || ''}` === key)) {
          acc.push(current);
        }
        return acc;
      }, []);
      
      // setCart(uniqueCart);
    } catch (error) {
      console.error('Error syncing cart:', error);
      // setCart([]);
      // localStorage.setItem('cart', JSON.stringify([]));
    }
  };
  


  useEffect(() => {
    syncCartWithLocalStorage();
  }, []);

  const checkAuthAndProceed = () => {
    if (!isAuthenticated) {
      setShowLoginModal(true);
      setShowAddressForm(false);
    } else {
      setShowAddressForm(true);
      setShowLoginModal(false);
    }
  };

  const handleLoginSuccess = (response) => {
    setShowLoginModal(false);
    setTimeout(() => {
      setShowAddressForm(true);
    }, 100);
  };

  useEffect(() => {
    if (!isCartOpen) {  // Changed from isOpen to isCartOpen
      setShowAddressForm(false);
      setShowLoginModal(false);
    }
  }, [isCartOpen]); 

  useEffect(() => {
    if (isCartOpen) {  // Changed from isOpen to isCartOpen
      document.body.classList.add('cart-open');
    } else {
      document.body.classList.remove('cart-open');
    }
    return () => {
      document.body.classList.remove('cart-open');
    };
  }, [isCartOpen]); 

  // const updateQuantity = (id, variationId, change) => {
  //   const updatedCart = cart.map(item => {
  //     if (item.productId === id && item.variationId === variationId) {
  //       const newQuantity = Math.max(1, item.quantity + change);
  //       return { ...item, quantity: newQuantity };
  //     }
  //     return item;
  //   }).filter(item => item.quantity > 0);
  
  //   setCart(updatedCart);
  //   updateLocalStorage(updatedCart);
  // };
  
  // In CartSidebar's removeFromCart function
// const removeFromCart = (id, variationId) => {
//   console.log('Before removal - Cart:', cart);
//   console.log('Removing item with ID:', id, 'and variationId:', variationId);
  
//   const updatedCart = cart.filter(item => 
//     !(item.productId === id && item.variationId === variationId)
//   );
  
//   console.log('After removal - Updated cart:', updatedCart);
//   setCart(updatedCart);
//   localStorage.setItem('cart', JSON.stringify(updatedCart));
  
//   // Verify localStorage after update
//   const storedCart = JSON.parse(localStorage.getItem('cart'));
//   console.log('LocalStorage after removal:', storedCart);
  
//   toast.success('Item removed from cart');
// };

  const addToCart = (item) => {
    const existingItemIndex = cart.findIndex(cartItem => 
      cartItem.productId === item.productId && 
      cartItem.variationId === item.variationId
    );

    let updatedCart;
    if (existingItemIndex > -1) {
      updatedCart = cart.map((cartItem, index) => 
        index === existingItemIndex 
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      );
    } else {
      updatedCart = [...cart, { ...item, quantity: 1 }];
    }

    // setCart(updatedCart);
    // updateLocalStorage(updatedCart);
    
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: updatedCart }
    }));
  };

  const getSubtotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getDiscountAmount = () => {
    if (!selectedCoupon) return 0;
    const subtotal = getSubtotal();
    return selectedCoupon.type === 'percentage' 
      ? subtotal * (selectedCoupon.discount / 100)
      : selectedCoupon.discount;
  };

  const getTotalPrice = () => {
    const subtotal = getSubtotal();
    const discount = getDiscountAmount();
    return (subtotal - discount).toFixed(2);
  };

  const applyCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    toast.success(`Coupon ${coupon.code} applied successfully!`);
  };

  const removeCoupon = () => {
    setSelectedCoupon(null);
    toast.info('Coupon removed');
  };

  const handleAddressSubmit = async (formData) => {
    try {
      setIsProcessing(true);
      
      const shippingAddress = {
        name: formData.fullName,
        phone: formData.phone,
        email: formData.email,
        address: `${formData.address1} ${formData.address2}`.trim(),
        city: formData.city,
        state: formData.state,
        pincode: formData.pincode,
        country: 'IN'
      };

      const finalTotal = parseFloat(getTotalPrice());
      const items = cart.map(item => ({
        productId: item.productId,
        variationId: item.variationId,
        quantity: item.quantity,
        price: item.price
      }));

      const response = await axios.post('/order/create', {
        items,
        shippingAddress,
        totalAmount: finalTotal
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create order');
      }

      const razorpayOptions = {
        key: response.data.razorpayConfig.key,
        amount: Math.round(finalTotal * 100),
        currency: response.data.razorpayConfig.currency,
        name: response.data.razorpayConfig.name,
        description: response.data.razorpayConfig.description,
        order_id: response.data.razorpayOrderId,
        handler: async (paymentResponse) => {
          try {
            const verificationResponse = await axios.post('/order/verify', {
              razorpayOrderId: response.data.razorpayOrderId,
              razorpayPaymentId: paymentResponse.razorpay_payment_id,
              razorpaySignature: paymentResponse.razorpay_signature
            });

            if (verificationResponse.data.success) {
              // setCart([]);
              localStorage.removeItem('cart');
              toast.success('Payment successful!');
              navigate('/checkout/order-success', {
                state: {
                  orderId: response.data.orderId,
                  paymentId: paymentResponse.razorpay_payment_id
                }
              });
            } else {
              throw new Error('Payment verification failed');
            }
          } catch (error) {
            console.error('Payment verification error:', error);
            toast.error('Payment verification failed. Please contact support.');
            navigate('/checkout/failed');
          }
        },
        prefill: response.data.razorpayConfig.prefill,
        notes: response.data.razorpayConfig.notes,
        theme: response.data.razorpayConfig.theme,
        modal: {
          ondismiss: () => {
            setIsProcessing(false);
          }
        }
      };

      const razorpayInstance = new window.Razorpay(razorpayOptions);
      razorpayInstance.open();
      
    } catch (error) {
      console.error('Checkout error:', error);
      toast.error(error.message || 'Checkout failed. Please try again.');
      setIsProcessing(false);
    }
  };

  return (
    <>
       {isCartOpen && <div className="cart-sidebar-overlay" onClick={closeCart}></div>}
       <div className={`cart-sidebar ${isCartOpen ? 'open' : ''}`}>
        {showAddressForm && isAuthenticated ? (
          <AddressForm 
            onSubmit={handleAddressSubmit}
            onClose={() => setShowAddressForm(false)}
            existingAddresses={[]}
          />
        ) : (
          <>
            <div className="cart-sidebar-header">
              <h2>Your Cart</h2>
              <button 
          onClick={closeCart} 
          className="close-btn"
          aria-label="Close cart"
        >✕</button>
            </div>

            <div className="cart-sidebar-items">
              {cart.length === 0 ? (
                <div className="empty-cart">
                  <span className="cart-icon">🛒</span>
                  <p>Your cart is empty</p>
                </div>
              ) : (
                cart.map((item) => (
                  <div key={`${item._id}-${item.variationId || ''}`} className="cart-item">
                    <img 
                      src={item.imageUrl && item.imageUrl[0]} 
                      alt={item.name} 
                      className="item-image"
                      onError={(e) => {
                        e.target.src = '/placeholder-image.jpg'; // Add a placeholder image
                      }}
                    />
                    <div className="item-details">
                      <h3>{item.name}</h3>
                      {item.variationName && (
                        <p className="variation-name">{item.variationName}</p>
                      )}
                      <p className="item-price">₹{item.price.toLocaleString('en-IN')}</p>
                      <div className="quantity-control">
                      <button 
  onClick={() => updateQuantity(item.productId, item.variationId, -1)}
  aria-label="Decrease quantity"
>-</button>
<span>{item.quantity}</span>
<button 
  onClick={() => updateQuantity(item.productId, item.variationId, 1)}
  aria-label="Increase quantity"
>+</button>
                      </div>
                    </div>
                    <button 
  onClick={() => removeFromCart(item.productId, item.variationId)}
  className="remove-btn"
  aria-label={`Remove ${item.name} from cart`}
>
  🗑️
</button>
                  </div>
                ))
              )}
            </div>

            <div className="cart-sidebar-coupons">
              <h3>Available Coupons</h3>
              {coupons.map((coupon) => (
                <button
                  key={coupon.id}
                  onClick={() => applyCoupon(coupon)}
                  className={`coupon-btn ${selectedCoupon?.id === coupon.id ? 'active' : ''}`}
                >
                  {coupon.code} - {coupon.type === 'percentage' ? `${coupon.discount}% off` : `₹${coupon.discount} off`}
                </button>
              ))}
              {selectedCoupon && (
                <button 
                  onClick={removeCoupon} 
                  className="remove-coupon-btn"
                  aria-label="Remove applied coupon"
                >
                  Remove Coupon
                </button>
              )}
            </div>

            <div className="cart-sidebar-footer">
              <div className="subtotal">
                <span>Subtotal:</span>
                <span>₹{getSubtotal().toLocaleString('en-IN')}</span>
              </div>
              {selectedCoupon && (
                <div className="discount">
                  <span>Discount:</span>
                  <span>-₹{getDiscountAmount().toLocaleString('en-IN')}</span>
                </div>
              )}
              <div className="total">
                <span>Total:</span>
                <span>₹{parseFloat(getTotalPrice()).toLocaleString('en-IN')}</span>
              </div>
              <button
                className="checkout-btn"
                onClick={checkAuthAndProceed}
                disabled={cart.length === 0 || isProcessing}
                aria-label={isProcessing ? 'Processing checkout' : 'Proceed to checkout'}
              >
                {isProcessing ? 'Processing...' : 'Proceed to Checkout'}
              </button>
            </div>
          </>
        )}
      </div>

      <LoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

export default CartSidebar;