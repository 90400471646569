import React, { useEffect, useState } from 'react';
import '../../styles/ComboBanner.scss'; 

const ComboBanner = () => {
  const [position, setPosition] = useState(-100);

  useEffect(() => {
    const animationInterval = setInterval(() => {
      setPosition((prevPosition) => {
        if (prevPosition > 100) {
          return -100;
        }
        return prevPosition + 1;
      });
    }, 20);

    return () => clearInterval(animationInterval);
  }, []);

  return (
    <div className="combo-banner-wrapper">
      <div className="combo-banner">
        <div 
          className="combo-banner__text"
          style={{ transform: `translateX(${position}%)` }}
        >
          Best Seller
        </div>
      </div>
    </div>
  );
};

export default ComboBanner;