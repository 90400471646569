import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Play, Pause } from 'lucide-react';
import '../../styles/ImageSlider.scss';

const ImageSlider = ({ images = [], interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    if (images.length === 0 || !isPlaying) return;
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % images.length);
    }, interval);
    return () => clearInterval(timer);
  }, [images.length, interval, isPlaying]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  if (images.length === 0) {
    return (
      <div className="slider-wrapper">
        <div className="slider-container empty">
          <p>No Images Available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="slider-wrapper">
      <div className="slider-container">
        <div className="slider">
          {images.map((image, index) => (
            <div
              key={index}
              className={`slide ${index === currentIndex ? 'active' : ''}`}
            >
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="slide-img"
                loading="lazy"
              />
            </div>
          ))}
        </div>

        <div className="slider-controls">
          <div className="navigation-arrows">
            <button onClick={handlePrev}>
              <ChevronLeft />
            </button>
            <button onClick={handleNext}>
              <ChevronRight />
            </button>
          </div>

          <button 
            className="play-pause-btn"
            onClick={() => setIsPlaying(!isPlaying)}
          >
            {isPlaying ? <Pause /> : <Play />}
          </button>

          <div className="slide-counter">
            {currentIndex + 1} / {images.length}
          </div>

          <div className="slider-dots">
            {images.map((_, index) => (
              <div
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
              />
            ))}
          </div>

          <div className="progress-bar">
            <div
              className="progress-bar__fill"
              style={{
                width: `${((currentIndex + 1) / images.length) * 100}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;