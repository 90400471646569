import React, { useState } from 'react';

const TermsSection = ({ title, children, isOpen, onToggle }) => (
  <div className="terms-section">
    <button
      className="terms-section__button"
      onClick={onToggle}
      aria-expanded={isOpen}
    >
      <h2>{title}</h2>
      <span className={`toggle-icon ${isOpen ? 'toggle-icon--open' : ''}`}>
        ⌄
      </span>
    </button>
    <div 
      className={`terms-section__content ${isOpen ? 'terms-section__content--open' : ''}`}
      aria-hidden={!isOpen}
    >
      {children}
    </div>
  </div>
);

const Privacy = () => {
  const [openSections, setOpenSections] = useState({
    cancellation: true,
    refund: false,
    privacy: false,
    cookies: false,
    security: false,
    control: false,
    contact: false
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className="terms-container">
      <div className="terms-content">
        <div className="terms-header">
          <h1>Privacy & Refund Policy</h1>
        </div>

        <div className="terms-sections">
          <TermsSection 
            title="1. Cancellation and Refund Policy" 
            isOpen={openSections.cancellation}
            onToggle={() => toggleSection('cancellation')}
          >
            <p><strong>Cancellation Terms</strong></p>
            <ul>
              <li>Cancellation requests accepted within 7 days of order placement</li>
              <li>Requests may be denied if shipping process has been initiated</li>
              <li>No cancellation accepted for perishable items (flowers, eatables)</li>
              <li>Refund/replacement possible for quality issues</li>
            </ul>
            
            <p><strong>Refund Process</strong></p>
            <ul>
              <li>Damaged/defective items must be reported within 7 days of receipt</li>
              <li>Customer service will evaluate complaints about product expectations</li>
              <li>Manufacturer warranty issues to be referred to manufacturers</li>
              <li>Approved refunds processed within 6-8 days</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="2. Information Collection" 
            isOpen={openSections.privacy}
            onToggle={() => toggleSection('privacy')}
          >
            <p>We may collect:</p>
            <ul>
              <li>Name</li>
              <li>Contact information including email address</li>
              <li>Demographic information</li>
              <li>Customer survey and offer-related information</li>
            </ul>
            
            <p><strong>Use of Information</strong></p>
            <ul>
              <li>Internal record keeping</li>
              <li>Service and product improvement</li>
              <li>Promotional communications</li>
              <li>Market research</li>
              <li>Website customization</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="3. Cookie Policy" 
            isOpen={openSections.cookies}
            onToggle={() => toggleSection('cookies')}
          >
            <ul>
              <li>Cookies used to analyze web traffic</li>
              <li>Helps tailor operations to user preferences</li>
              <li>Users can choose to decline cookies</li>
              <li>Traffic log cookies used for statistical analysis</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="4. Data Security" 
            isOpen={openSections.security}
            onToggle={() => toggleSection('security')}
          >
            <ul>
              <li>Committed to information security</li>
              <li>Measures in place to prevent unauthorized access</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="5. Personal Information Control" 
            isOpen={openSections.control}
            onToggle={() => toggleSection('control')}
          >
            <ul>
              <li>Users may restrict information collection</li>
              <li>Option to opt-out of marketing communications</li>
              <li>Information not sold/distributed to third parties without permission</li>
              <li>Users may request information correction</li>
            </ul>
            
            <p><strong>Updates to Privacy Policy</strong></p>
            <ul>
              <li>Policy may be updated periodically</li>
              <li>Users should check regularly for changes</li>
              <li>Changes effective upon posting</li>
            </ul>
          </TermsSection>

          <TermsSection 
            title="6. Contact Information" 
            isOpen={openSections.contact}
            onToggle={() => toggleSection('contact')}
          >
            <p><strong>Contact for Privacy Concerns</strong></p>
            <ul>
              <li><strong>Company Name:</strong> Periodic Industries</li>
              <li><strong>Address:</strong> PLOT NO 65, SURVEY NO 213-214, LAKHABAVAD, Jamnagar, Gujarat, 361006</li>
              <li><strong>Phone:</strong> 7788994668</li>
              <li><strong>Email:</strong> CARE@BUYMALAK.IN</li>
            </ul>
          </TermsSection>
        </div>

        <div className="terms-footer">
          <p>If you have any questions about our privacy policy, please contact us at CARE@BUYMALAK.IN</p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;