import React from 'react';
import '../../styles/FilterComponent.scss';

const FilterComponent = ({
  filterCategory,
  setFilterCategory,
  priceRange,
  setPriceRange,
  availability,
  setAvailability,
  isMobileFilterOpen,
  onClose,
  onSearchClear
}) => {
  const handleClearFilters = () => {
    setFilterCategory('All Products');
    setPriceRange(1000);
    setAvailability('All');
    if (onSearchClear) {
      onSearchClear();
    }
  };

  return (
    <>
      <div className={`filter-wrapper ${isMobileFilterOpen ? 'mobile-open' : ''}`}>
        <div className="filter-component">
          <div className="filter-header">
            <h2 className="filter-title">
              <span className="filter-icon"></span>
              Filters
            </h2>
            <div className="filter-actions">
              <button className="clear-all" onClick={handleClearFilters}>
                Clear All
              </button>
              <button className="close-button" onClick={onClose} aria-label="Close filters">
                ×
              </button>
            </div>
          </div>

          <div className="filter-content">
            <div className="filter-group">
              <label htmlFor="category">Category</label>
              <select 
                id="category"
                value={filterCategory}
                onChange={(e) => setFilterCategory(e.target.value)}
              >
                <option value="All Products">All Products</option>
                <option value="Laundry Care">Laundry Care</option>
                <option value="Kitchen Care">Kitchen Care</option>
                <option value="Surface Care">Surface Care</option>
                <option value="Bathroom Care">Bathroom Care</option>
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="price">Price: ₹{priceRange}</label>
              <input 
                id="price"
                type="range" 
                min="0" 
                max="1000" 
                value={priceRange}
                onChange={(e) => setPriceRange(Number(e.target.value))}
              />
              <div className="range-labels">
                <span>₹0</span>
                <span>₹1000</span>
              </div>
            </div>

            <div className="filter-group">
              <label htmlFor="availability">Availability</label>
              <select 
                id="availability"
                value={availability}
                onChange={(e) => setAvailability(e.target.value)}
              >
                <option value="All">All</option>
                <option value="In Stock">In Stock</option>
                <option value="Out of Stock">Out of Stock</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {isMobileFilterOpen && <div className="filter-overlay" onClick={onClose} />}
    </>
  );
};

export default FilterComponent;