import React, { useState, useEffect } from 'react';
import Footer from './HomePageComponents/Footer';
import { useNavigate } from 'react-router-dom';
import FilterComponent from './ProductPageComponents/FilterComponent';
import ProductCard from './ProductPageComponents/ProductCard';
import '../styles/ProductPage.scss';
import instance from '../helpers/axiosconfig';
import CartSidebar from './CartPages/CartPage';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductPage() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('price-asc');
  const [filterCategory, setFilterCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [priceRange, setPriceRange] = useState(2000);
  const [isCartOpen, setIsCartOpen] = useState(false);  
  const [cart, setCart] = useState([]);
  const [volumeRange, setVolumeRange] = useState('All');
  const [fragrance, setFragrance] = useState('All');
  const [availability, setAvailability] = useState('All');
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    // Get category from URL parameters
    const params = new URLSearchParams(window.location.search);
    const categoryFromURL = params.get('category');
    
    if (categoryFromURL) {
      // Set the filter category from URL
      setFilterCategory(decodeURIComponent(categoryFromURL));
    } else {
      // Reset to All Products if no category specified
      setFilterCategory('All Products');
    }
    
    fetchProducts();
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, [window.location.search]); // React to URL changes

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await instance.get('/products');
      // Create separate product entries for each variation while maintaining the original structure
      const expandedProducts = response.data.flatMap(product => 
        product.variations.map(variation => ({
          _id: product._id,
          category: product.category,
          baseDescription: product.baseDescription,
          variations: [variation] // Keep the variations array structure but with only one variation
        }))
      );
      setProducts(expandedProducts);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
    }
  };

  const doesProductMatchFilters = (product) => {
    const variation = product.variations[0];
    
    // Check if all filters are in their default state
    const isDefaultFilters = 
      filterCategory === 'All Products' && 
      priceRange === 1000 && 
      availability === 'All' && 
      !searchTerm;

    // If all filters are default, show all products
    if (isDefaultFilters) {
      return true;
    }

    // Category matching (case-insensitive)
    const matchesCategory = 
      filterCategory === 'All Products' || 
      product.category.toLowerCase().includes(
        filterCategory.toLowerCase().replace(' care', '')
      );

    // Search term matching
    const matchesSearch = !searchTerm || 
      variation.name.toLowerCase().includes(searchTerm.toLowerCase());

    // Price matching (considering discount)
    const discountedPrice = variation.price * (1 - (variation.discount || 0) / 100);
    const matchesPrice = discountedPrice <= priceRange;

    // Availability matching
    const matchesAvailability = 
      availability === 'All' || 
      (availability === 'In Stock' && variation.stock > 0) ||
      (availability === 'Out of Stock' && variation.stock === 0);

    return matchesCategory && matchesSearch && matchesPrice && matchesAvailability;
  };

const clearSearch = () => {
  setSearchTerm('');
};

  const getFilterOptions = () => {
    const volumes = new Set();
    const fragrances = new Set();
    const categories = new Set();
    
    products.forEach(product => {
      const variation = product.variations[0];
      if (variation.volume) volumes.add(variation.volume);
      if (variation.fragrance) fragrances.add(variation.fragrance);
      if (product.category) categories.add(product.category);
    });

    return {
      volumes: ['All', ...Array.from(volumes)].sort(),
      fragrances: ['All', ...Array.from(fragrances)].sort(),
      categories: ['All', ...Array.from(categories)].sort()
    };
  };

  const sortProducts = (products) => {
    return [...products].sort((a, b) => {
      const aVariation = a.variations[0];
      const bVariation = b.variations[0];
      
      if (sortBy === 'name') {
        return aVariation.name.localeCompare(bVariation.name);
      }
      const aPrice = aVariation.price * (1 - (aVariation.discount || 0) / 100);
      const bPrice = bVariation.price * (1 - (bVariation.discount || 0) / 100);
      if (sortBy === 'price-asc') {
        return aPrice - bPrice;
      }
      if (sortBy === 'price-desc') {
        return bPrice - aPrice;
      }
      return 0;
    });
  };

  const addToCart = (cartItem) => {
    const existingItemIndex = cart.findIndex(item => 
      item.productId === cartItem.productId && 
      item.variationId === cartItem.variationId
    );
    
    let updatedCart;
    if (existingItemIndex > -1) {
      // Update existing item
      updatedCart = cart.map((item, index) => 
        index === existingItemIndex 
          ? { ...item, quantity: item.quantity + cartItem.quantity }
          : item
      );
    } else {
      // Add new item
      updatedCart = [...cart, cartItem];
    }
    
    // Update state and localStorage atomically
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    toast.success(`${cartItem.name} added to cart`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'custom-toast',
      progressClassName: 'custom-toast-progress',
      icon: '🛒'
    });
  
    // Dispatch cart update event
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: updatedCart }
    }));
  };

  const handleProductClick = (productId, variationId) => {
    navigate(`/product/${productId}`, { 
      state: { selectedVariationId: variationId }
    });
  };

  const toggleMobileFilter = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
    document.body.style.overflow = !isMobileFilterOpen ? 'hidden' : 'auto';
  };

  const filteredProducts = products.filter(doesProductMatchFilters);
  const filteredAndSortedProducts = sortProducts(filteredProducts);
  const filterOptions = getFilterOptions();

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="product-page">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={true}
        pauseOnHover={true}
        theme="colored"
        toastStyle={{ 
          background: '#066469',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(6, 100, 105, 0.3)'
        }}
        progressStyle={{ background: 'rgba(255, 255, 255, 0.4)' }}
        closeButton={false}
        icon={true}
        limit={3}
        transition={Slide}
      />
      
      <header className="header">
        <h1>All Products</h1>
        <button className="mobile-filter-toggle" onClick={toggleMobileFilter}>
          {isMobileFilterOpen ? '✕' : '☰'}
        </button>
      </header>
      
      <main className="main-content">
      <div className={`filter-container ${isMobileFilterOpen ? 'mobile-open' : ''}`}>
        <FilterComponent
          filterCategory={filterCategory}
          setFilterCategory={(category) => {
            setFilterCategory(category);
            // Update URL when filter changes
            const params = new URLSearchParams(window.location.search);
            if (category === 'All Products') {
              params.delete('category');
            } else {
              params.set('category', category);
            }
            navigate(`/products?${params.toString()}`, { replace: true });
          }}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
          availability={availability}
          setAvailability={setAvailability}
          isMobileFilterOpen={isMobileFilterOpen}
          onClose={() => setIsMobileFilterOpen(false)}
          onSearchClear={clearSearch}
        />
      </div>
        
        <div className="product-area">
          <div className="search-sort">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="search-icon">🔍</span>
            </div>
            <div className="sort-dropdown">
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="name">Name</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
              </select>
            </div>
          </div>
          
          <div className="product-grid">
  {filteredAndSortedProducts.map((product) => (
    <ProductCard
    key={`${product._id}-${product.variations[0]._id}`}
    product={product}
    onAddToCart={(cartItem) => addToCart(cartItem)}  // Keep this as is - we'll format in ProductCard
    onProductClick={(productId, variationId) => handleProductClick(productId, variationId)}
    onOpenCart={() => setIsCartOpen(true)}
  />
  ))}
</div>
        </div>
      </main>
      
      <Footer />
      <CartSidebar 
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cart={cart}
        setCart={setCart}
      />
      
      {isMobileFilterOpen && (
        <div className="mobile-overlay" onClick={toggleMobileFilter} />
      )}
    </div>
  );
}

export default ProductPage;