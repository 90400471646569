import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../helpers/axiosconfig';
import '../../styles/PaymentCheckout.scss';

const PaymentCheckout = () => {
  const navigate = useNavigate();
  const [checkoutData, setCheckoutData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('razorpay');

  useEffect(() => {
    const savedCheckoutData = localStorage.getItem('checkoutData');
    if (!savedCheckoutData) {
      navigate('/cart');
      return;
    }
    setCheckoutData(JSON.parse(savedCheckoutData));
  }, [navigate]);

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePaymentSubmit = async (e) => {
    if (e) e.preventDefault();
    if (paymentMethod === 'razorpay') {
      await handleRazorpayPayment();
    }
  };

  const handleRazorpayPayment = async () => {
    try {
      setLoading(true);

      // Initialize Razorpay
      const res = await initializeRazorpay();
      if (!res) {
        throw new Error('Razorpay SDK failed to load');
      }

      // Create order on your backend
      const orderData = {
        amount: checkoutData.pricing.total,
        currency: 'INR',
        items: checkoutData.cart.map(item => ({
          productId: item._id,
          quantity: item.quantity,
          price: item.price
        })),
        shippingAddress: checkoutData.shippingAddress
      };

      const response = await axios.post('/order/create', orderData);
      const { razorpayConfig } = response.data;

      // Configure Razorpay options
      const options = {
        ...razorpayConfig,
        handler: async function(response) {
          try {
            // Verify payment
            const verifyResponse = await axios.post('/order/verify', {
              razorpayOrderId: response.razorpay_order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpaySignature: response.razorpay_signature
            });

            if (verifyResponse.data.success) {
              // Clear cart and checkout data
              localStorage.removeItem('cart');
              localStorage.removeItem('checkoutData');

              // Navigate to success page
              navigate('/order-success', { 
                state: { 
                  orderId: verifyResponse.data.order.id,
                  amount: verifyResponse.data.order.amount,
                  paymentId: response.razorpay_payment_id,
                  orderDetails: verifyResponse.data.order
                }
              });
            } else {
              throw new Error('Payment verification failed');
            }
          } catch (error) {
            console.error('Payment verification failed:', error);
            navigate('/order-failed', {
              state: {
                error: 'Payment verification failed. Please check your order status.',
                orderData: orderData
              }
            });
          }
        },
        prefill: {
          name: checkoutData.shippingAddress.fullName,
          email: checkoutData.shippingAddress.email || '',
          contact: checkoutData.shippingAddress.phone
        },
        theme: {
          color: '#3399cc'
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    } catch (error) {
      console.error('Payment initialization failed:', error);
      alert(error.message || 'Failed to initialize payment');
    } finally {
      setLoading(false);
    }
  };

  if (!checkoutData) return null;

  return (
    <div className="payment-container">
      <div className="payment-grid">
        <div className="payment-section">
          <div className="payment-header">
            <h1>Payment Method</h1>
            <div className="checkout-steps">
              <span className="step completed">Address</span>
              <span className="step active">Payment</span>
              <span className="step">Confirmation</span>
            </div>
          </div>

          <form onSubmit={handlePaymentSubmit}>
            <div className="payment-methods">
              <label className={`payment-method ${paymentMethod === 'razorpay' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="razorpay"
                  checked={paymentMethod === 'razorpay'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                <div className="method-content">
                  <div className="method-icon">💳</div>
                  <div className="method-details">
                    <h3>Pay Online</h3>
                    <p>Pay using Card, UPI, Netbanking</p>
                  </div>
                </div>
              </label>

              <label className={`payment-method ${paymentMethod === 'cod' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="cod"
                  checked={paymentMethod === 'cod'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  disabled
                />
                <div className="method-content">
                  <div className="method-icon">💰</div>
                  <div className="method-details">
                    <h3>Cash on Delivery</h3>
                    <p>Currently unavailable</p>
                  </div>
                </div>
              </label>
            </div>

            <button 
              type="submit" 
              className="payment-button"
              disabled={loading}
            >
              {loading ? 'Processing...' : `Pay ₹${checkoutData.pricing.total.toLocaleString('en-IN')}`}
            </button>
          </form>
        </div>

        <div className="order-summary">
          <h2>Order Summary</h2>
          <div className="delivery-address">
            <h3>Delivery Address</h3>
            <div className="address-details">
              <p><strong>{checkoutData.shippingAddress.fullName}</strong></p>
              <p>{checkoutData.shippingAddress.address1}</p>
              {checkoutData.shippingAddress.address2 && (
                <p>{checkoutData.shippingAddress.address2}</p>
              )}
              <p>
                {checkoutData.shippingAddress.city}, {checkoutData.shippingAddress.state}
                {" "}{checkoutData.shippingAddress.pincode}
              </p>
              <p>Phone: {checkoutData.shippingAddress.phone}</p>
            </div>
          </div>

          <div className="summary-items">
            {checkoutData.cart.map(item => (
              <div key={item._id} className="summary-item">
                <img src={item.imageUrl[0]} alt={item.name} />
                <div className="item-details">
                  <h4>{item.name}</h4>
                  <p>Qty: {item.quantity}</p>
                  <p className="price">₹{(item.price * item.quantity).toLocaleString('en-IN')}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="summary-totals">
            <div className="summary-row">
              <span>Subtotal</span>
              <span>₹{checkoutData.pricing.subtotal.toLocaleString('en-IN')}</span>
            </div>
            {checkoutData.pricing.discount > 0 && (
              <div className="summary-row discount">
                <span>Discount</span>
                <span>-₹{checkoutData.pricing.discount.toLocaleString('en-IN')}</span>
              </div>
            )}
            <div className="summary-row total">
              <span>Total</span>
              <span>₹{checkoutData.pricing.total.toLocaleString('en-IN')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCheckout;